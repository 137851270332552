import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219')
];

export const server_loads = [0,23,26,25,27,28,29,30,32,35,2,4,5,6,7,8,9,10,11,12,13,14,15,16,22,17,19];

export const dictionary = {
		"/": [~39],
		"/admin": [~118,[23],[24]],
		"/admin/companies": [~119,[23],[24]],
		"/admin/companies/[companySlug]": [~120,[23],[24]],
		"/admin/companies/[companySlug]/edit": [~121,[23],[24]],
		"/admin/companies/[companySlug]/edit/accesses": [122,[23],[24]],
		"/admin/companies/[companySlug]/edit/licenses": [123,[23],[24]],
		"/admin/companies/[companySlug]/projects": [~124,[23],[24]],
		"/admin/companies/[companySlug]/users": [~125,[23],[24]],
		"/admin/country-risk": [~126,[23],[24]],
		"/admin/devtools": [127,[23],[24]],
		"/admin/devtools/details": [~128,[23],[24]],
		"/admin/emails": [~129,[23],[24]],
		"/admin/emails/email-dashboard": [~130,[23],[24]],
		"/admin/event-log": [~131,[23],[24]],
		"/admin/licenses": [132,[23],[24]],
		"/admin/licenses/susan": [~133,[23],[24]],
		"/admin/new-company": [134,[23],[24]],
		"/admin/questions": [~135,[23],[24]],
		"/admin/questions/add": [~146,[23],[24]],
		"/admin/questions/options": [147,[23],[24]],
		"/admin/questions/options/add": [~151,[23],[24]],
		"/admin/questions/options/[optionId]": [~148,[23],[24]],
		"/admin/questions/options/[optionId]/edit": [~149,[23],[24]],
		"/admin/questions/options/[optionId]/localizations/[locale]": [~150,[23],[24]],
		"/admin/questions/restructure": [~152,[23],[24]],
		"/admin/questions/sets": [~153,[23],[24]],
		"/admin/questions/sets/[questionSetId]": [~154,[23,26],[24]],
		"/admin/questions/sets/[questionSetId]/access": [~155,[23,26],[24]],
		"/admin/questions/sets/[questionSetId]/edit-details": [~156,[23,26],[24]],
		"/admin/questions/sets/[questionSetId]/print": [~157],
		"/admin/questions/[questionId]": [136,[23,25],[24]],
		"/admin/questions/[questionId]/details": [137,[23,25],[24]],
		"/admin/questions/[questionId]/edit": [138,[23,25],[24]],
		"/admin/questions/[questionId]/follow-ups": [~139,[23,25],[24]],
		"/admin/questions/[questionId]/localizations": [140,[23,25],[24]],
		"/admin/questions/[questionId]/localizations/[locale]": [~141,[23,25],[24]],
		"/admin/questions/[questionId]/options": [~142,[23,25],[24]],
		"/admin/questions/[questionId]/scores": [~143,[23,25],[24]],
		"/admin/questions/[questionId]/ungc": [~144,[23,25],[24]],
		"/admin/questions/[questionId]/update-ungc": [~145,[23,25],[24]],
		"/admin/sent-emails/[email]/[emailType]": [~158],
		"/admin/suggested-follow-ups": [~159,[23],[24]],
		"/admin/suggested-follow-ups/[questionId]": [~160,[23,27],[24]],
		"/admin/suggested-follow-ups/[questionId]/localizations": [~161,[23,27],[24]],
		"/admin/taxonomy": [162,[23,28],[24]],
		"/admin/taxonomy/version": [183,[23,28],[24]],
		"/admin/taxonomy/version/[versionId]": [~184,[23,28],[24]],
		"/admin/taxonomy/year": [~185,[23,28],[24]],
		"/admin/taxonomy/[versionId]": [163,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/activities": [164,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/activities/[activityId]": [~165,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/activity-based": [~166,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/categories": [~167,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/categories/edit": [~168,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/company-wide": [~169,[23,28,29,30],[24]],
		"/admin/taxonomy/[versionId]/company-wide/text": [~170,[23,28,29,30],[24]],
		"/admin/taxonomy/[versionId]/expiration": [~171,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/kpi-texts": [172,[23,28,29,31],[24]],
		"/admin/taxonomy/[versionId]/kpi-texts/[kpi]": [~173,[23,28,29,31],[24]],
		"/admin/taxonomy/[versionId]/question-sets": [~177,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/question-sets/[setId]": [~178,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]": [~174,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/localizations/[locale]": [~175,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/update-scoring": [~176,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/reusable-group": [~179,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/reusable-group/[groupId]": [~180,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/sectors": [181,[23,28,29],[24]],
		"/admin/taxonomy/[versionId]/sectors/[sectorId]": [~182,[23,28,29],[24]],
		"/admin/transparency-act-steps": [~186,[23],[24]],
		"/admin/transparency-act-steps/add": [190,[23],[24]],
		"/admin/transparency-act-steps/checklist-item": [~191,[23],[24]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]": [~192,[23],[24]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]/add-localization": [~193,[23],[24]],
		"/admin/transparency-act-steps/[taStepId]": [~187,[23],[24]],
		"/admin/transparency-act-steps/[taStepId]/add-checklist-item": [188,[23],[24]],
		"/admin/transparency-act-steps/[taStepId]/add-localization": [~189,[23],[24]],
		"/admin/users": [~194,[23],[24]],
		"/admin/users/[userId]": [~195,[23],[24]],
		"/admin/users/[userId]/edit": [~196,[23],[24]],
		"/admin/workers": [~197,[23,32],[24]],
		"/data-processing-agreement": [198,[33]],
		"/login": [199],
		"/logout": [200],
		"/maintenance": [201],
		"/notifications": [~202,[34]],
		"/survey-invitation/[invitationId]": [~203,[35]],
		"/survey-invitation/[invitationId]/access-requested": [~204,[35]],
		"/survey-invitation/[invitationId]/company-created": [~205,[35]],
		"/survey-invitation/[invitationId]/declined": [207,[35]],
		"/survey-invitation/[invitationId]/decline": [206,[35]],
		"/survey-invitation/[invitationId]/forwarded": [209,[35]],
		"/survey-invitation/[invitationId]/forward": [208,[35]],
		"/survey-invitation/[invitationId]/new-company": [~210,[35]],
		"/survey-invitation/[invitationId]/new-company/access-requested": [211,[35]],
		"/survey-invitation/[invitationId]/no-access": [~212,[35]],
		"/survey-invitation/[invitationId]/request-access/[companyId]": [~213,[35]],
		"/survey-invitation/[invitationId]/request-access/[companyId]/access-requested": [~214,[35]],
		"/survey-invitation/[invitationId]/select-company": [~215,[35]],
		"/terms-of-use": [216,[36]],
		"/user": [217,[37],[38]],
		"/user/access-tokens": [~218,[37],[38]],
		"/user/access-tokens/create": [~219,[37],[38]],
		"/[companySlug]": [~40,[2],[3]],
		"/[companySlug]/admin/add-company": [~41,[2],[3]],
		"/[companySlug]/company": [~42,[2,4],[3]],
		"/[companySlug]/company/accesses": [~43,[2,4],[3]],
		"/[companySlug]/company/assessments": [~44,[2,4],[3]],
		"/[companySlug]/company/documents": [~45,[2,4],[3]],
		"/[companySlug]/company/edit": [46,[2,4],[3]],
		"/[companySlug]/company/licenses": [~47,[2,4],[3]],
		"/[companySlug]/company/overview": [~48,[2,4],[3]],
		"/[companySlug]/company/saq-results": [~49,[2,4],[3]],
		"/[companySlug]/company/tags": [~50,[2,4],[3]],
		"/[companySlug]/company/users": [~51,[2,4],[3]],
		"/[companySlug]/customers": [~52,[2],[3]],
		"/[companySlug]/customers/[customerId]": [~53,[2],[3]],
		"/[companySlug]/customers/[customerId]/overview": [54,[2,5],[3]],
		"/[companySlug]/customers/[customerId]/overview/messages": [~55,[2,5],[3]],
		"/[companySlug]/follow-ups": [56,[2,6],[3]],
		"/[companySlug]/follow-ups/received": [~57,[2,6],[3]],
		"/[companySlug]/follow-ups/received/[senderId]/[questionId]": [~58,[2],[3]],
		"/[companySlug]/follow-ups/sent": [~59,[2,6],[3]],
		"/[companySlug]/follow-ups/sent/[receiverId]/[questionId]": [~60,[2],[3]],
		"/[companySlug]/projects": [~61,[2,7],[3]],
		"/[companySlug]/projects/add": [~69,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(main)": [62,[2,7,8],[3]],
		"/[companySlug]/projects/[projectId]/(main)/chain/[chainId]": [~63,[2,7,8],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/edit-suppliers-list": [~64,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/preview": [~65,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/preview/print": [~66],
		"/[companySlug]/projects/[projectId]/(sub-pages)/send-survey": [~67,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/settings": [~68,[2,7],[3]],
		"/[companySlug]/relogin": [~70],
		"/[companySlug]/saq/[questionSetId]": [~71,[9]],
		"/[companySlug]/saq/[questionSetId]/answer/[...questionPath]": [~72,[9]],
		"/[companySlug]/saq/[questionSetId]/finish": [~73,[10]],
		"/[companySlug]/suppliers": [~74,[2,11],[3]],
		"/[companySlug]/suppliers/add": [~86,[2,11],[3]],
		"/[companySlug]/suppliers/upload": [~87,[2,11],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]": [~88,[2,11],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]/status": [~89,[2,11],[3]],
		"/[companySlug]/suppliers/[supplierId]": [~75,[2,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/edit": [~76,[2,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview": [77,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents": [~78,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents/[uploadId]": [~79,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/follow-ups": [~80,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/history": [81,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/messages": [~82,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results": [~83,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]": [~84,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/print": [~85],
		"/[companySlug]/surveys": [~90,[2],[3]],
		"/[companySlug]/surveys/[surveyId]": [~91,[13]],
		"/[companySlug]/surveys/[surveyId]/access-requested": [~92],
		"/[companySlug]/surveys/[surveyId]/answer": [~93,[13,14]],
		"/[companySlug]/surveys/[surveyId]/answer/[...questionPath]": [~94,[13,14]],
		"/[companySlug]/surveys/[surveyId]/finish": [~95,[15]],
		"/[companySlug]/surveys/[surveyId]/finish/thank-you": [~96,[15]],
		"/[companySlug]/surveys/[surveyId]/no-access": [~97],
		"/[companySlug]/taxonomy": [~98,[16]],
		"/[companySlug]/taxonomy/overview": [~116,[16,22]],
		"/[companySlug]/taxonomy/overview/import-report": [~117,[16,22]],
		"/[companySlug]/taxonomy/[reportId]": [99,[17]],
		"/[companySlug]/taxonomy/[reportId]/company/minimumSafeguards": [~100,[17,18]],
		"/[companySlug]/taxonomy/[reportId]/company/pais": [~101,[17,18]],
		"/[companySlug]/taxonomy/[reportId]/evaluate": [~102,[17]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]": [~103,[17,19]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]/[activityId]": [~104,[17,19]],
		"/[companySlug]/taxonomy/[reportId]/financial": [~105,[17,20]],
		"/[companySlug]/taxonomy/[reportId]/financial/activity": [~107,[17,20]],
		"/[companySlug]/taxonomy/[reportId]/financial/layout": [108,[17,20]],
		"/[companySlug]/taxonomy/[reportId]/financial/[entityId]/[activityId]": [~106,[17,20]],
		"/[companySlug]/taxonomy/[reportId]/manage": [~109,[17]],
		"/[companySlug]/taxonomy/[reportId]/manage/[entityId]": [~110,[17]],
		"/[companySlug]/taxonomy/[reportId]/report": [111,[17]],
		"/[companySlug]/taxonomy/[reportId]/start": [~112,[17]],
		"/[companySlug]/taxonomy/[reportId]/summary": [113,[17,21]],
		"/[companySlug]/taxonomy/[reportId]/summary/downloads": [~114,[17,21]],
		"/[companySlug]/taxonomy/[reportId]/summary/kpi": [~115,[17,21]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.js';